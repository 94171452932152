import { IntlProvider } from 'react-intl';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingMaster from './components/pages/landing/LandingMaster';
import { connect } from "react-redux";
import * as appActions from "./store/actions/AppActions";
import * as accountActions from "./store/actions/AccountActions";
import { ApplicationUser } from './models/AuthModels';
import LocalStorageService from './services/LocalStorageService';
import AppRoutes from './components/AppRoutes';
import UITestPage from './components/pages/UITestPage';
import Loader from './components/controls/loader/Loader';
import strings from './resources/Strings';
import { appConfig } from './AppConfig';
import AdminPortalMaster from './components/pages/admin_portal/AdminPortalMaster';
import AdminRoutes from './components/AdminRoutes';
import { jwtDecode } from 'jwt-decode';
import { JwtPayload } from './models/JwtPayload';
import { Backdrop } from '@mui/material';

interface AppProps {
	locale: string,
	isUserLoggedIn: boolean,
	changeLocale: Function,
	setCurrentUser: {(user: ApplicationUser): void},
	currentUser?: ApplicationUser,
	globalLoaderVisible?: boolean,
	showGlobalLoader?: Function,
	hideGlobalLoader?: Function,
	updateAppVersion?: Function
}

interface AppState {
}

class App extends React.Component<AppProps, AppState> {
	constructor(props: AppProps) {
		super(props);

		this.state = {
		}
	}

	componentDidMount(): void {
		this.setInitialLocalization();
		this.setAppVersion();

		if (this.props.isUserLoggedIn) {
			this.setUserInfo();
		}

		if(this.props.hideGlobalLoader)
			this.props.hideGlobalLoader();
	}

	setInitialLocalization() {
		var currentLocale = LocalStorageService.getLocalization();
		if (!currentLocale) {
			LocalStorageService.setLocalization('en-us');
			this.props.changeLocale('en-us');
		}
		else {
			this.props.changeLocale(currentLocale);
		}
	}

	setAppVersion() {
		// get the version from the Rest API or set it manually
		this.props.updateAppVersion?.(appConfig.version);
	}

	setUserInfo() {
		// get the current user info from the REST API or from Access Token
		const accessToken = LocalStorageService.getAccessToken();
		if(accessToken){
			const decoded = jwtDecode<JwtPayload>(accessToken);
			this.props.setCurrentUser?.({
				id: decoded.id,
				email: decoded.email,
				username: decoded.username,
				roles: decoded.roles
			});
		}
	}

	render() {
		return (
			<>
				{
					this.props.globalLoaderVisible && <Backdrop
						sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} 
						open={this.props.globalLoaderVisible ?? false}>
						<Loader />
					</Backdrop>
				}
				<IntlProvider
					locale={this.props.locale}
					messages={strings[this.props.locale]}>
					<BrowserRouter>
						<Routes>
							{/* Comment out ui test page route for production */}
							<Route path="/ui-test-page" element={<UITestPage />} key="/ui-test-page" />,
							<Route
								element={<LandingMaster />}>
								{AppRoutes}
							</Route>
							<Route
								path="/admin"
								element={<AdminPortalMaster />}>
								{AdminRoutes}
							</Route>
						</Routes>
					</BrowserRouter>
				</IntlProvider>
			</>
		);
	}

}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.account.currentUser,
		locale: state.app.locale,
		isUserLoggedIn: state.auth.isUserLoggedIn,
		globalLoaderVisible: state.app.globalLoaderVisible
	};
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		changeLocale: (locale: string) =>
			dispatch(appActions.ChangeLocale(locale)),
		setCurrentUser: (user: ApplicationUser) =>
			dispatch(accountActions.SetCurrentUser(user)),
		showGlobalLoader: () =>
			dispatch(appActions.ShowGlobalLoader()),
		hideGlobalLoader: () =>
			dispatch(appActions.HideGlobalLoader()),
		updateAppVersion: (version: string) =>
			dispatch(appActions.UpdateAppVersion(version))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
