import React, { ChangeEvent } from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import { connect } from "react-redux";
import { Box, Button, Container, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Switch, TextField } from "@mui/material";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import * as appActions from "../../../../store/actions/AppActions";
import { UserModel } from "../../../../models/UserModel";
import { ValidationError } from "../../../../models/ValidationErrorModel";
import { RoleModel } from "../../../../models/RoleModel";
import { ApplicationUser } from "../../../../models/AuthModels";
import { appConfig } from "../../../../AppConfig";
import InsufficientAccess from "../../InsufficientAccess";

interface UserProps extends WrappedComponentProps, WithRouterProps {
	isUserLoggedIn?: boolean,
	currentUser?: ApplicationUser,
	showGlobalLoader?: Function,
	hideGlobalLoader?: Function,
}

interface UserState {
	id?: string;
	username: string;
	usernameIsValid?: boolean;
	usernameErrorMessage?: string;
	email: string;
	emailIsValid?: boolean;
	emailErrorMessage?: string;
	phoneNumber?: string;
	phoneNumberIsValid?: boolean;
	phoneNumberErrorMessage?: string;
	firstName: string;
	firstNameIsValid?: boolean;
	firstNameErrorMessage?: string;
	middleName?: string;
	middleNameIsValid?: boolean;
	middleNameErrorMessage?: string;
	lastName: string;
	lastNameIsValid?: boolean;
	lastNameErrorMessage?: string;
	password: string;
	passwordIsValid?: boolean;
	passwordErrorMessage?: string;
	confirmPassword: string;
	confirmPasswordIsValid?: boolean;
	confirmPasswordErrorMessage?: string;
	roles?: Array<string>;
	isActive: boolean;
	isEmailVerified: boolean;
	isPhoneNumberVerified: boolean;
	allRoles?: Array<RoleModel>;
	insufficientAccess: boolean;
}

class User extends React.Component<UserProps, UserState> {
	private roles: Array<String>;
	private hasAccess: boolean;

	constructor(props: UserProps) {
		super(props);

		this.roles = this.props.currentUser?.roles ?? [];
		this.hasAccess = (this.props.isUserLoggedIn && (this.roles.includes(appConfig.roles.SUPER_ADMIN) || this.roles.includes(appConfig.roles.ADMIN))) ?? false;

		this.state = {
			username: "",
			email: "",
			phoneNumber: "",
			firstName: "",
			middleName: "",
			lastName: "",
			password: "",
			confirmPassword: "",
			isActive: true,
			isEmailVerified: false,
			isPhoneNumberVerified: false,
			insufficientAccess: false
		}
	}

	componentDidMount(): void {
		if(this.props.hideGlobalLoader)
			this.props.hideGlobalLoader();

		this.loadData();
	}

	async loadData(){
		if(this.hasAccess){
			if(this.props.showGlobalLoader)
				this.props.showGlobalLoader();

			await this.getRoles();

			if(this.props.hideGlobalLoader)
				this.props.hideGlobalLoader();
		}

		if(this.props.params?.id){
			await this.getData();
		}
	}

	async onFieldChangeAsync(event: ChangeEvent<HTMLInputElement>) {
		const { name, value } = event.target;

		if (name === "username" && this.hasAccess) {
			const isValid = value.length >= 1;

			await this.setState({
				username: value,
				usernameIsValid: isValid,
				usernameErrorMessage: !isValid ? "Username is required" : ""
			});
		}

		if (name === "email") {
			let emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

			const isValid = value.length >= 1 && emailRegex.test(value);

			await this.setState({
				email: value,
				emailIsValid: isValid,
				emailErrorMessage: !isValid ? "Email is required and should contain valid email address" : ""
			});
		}

		if (name === "phonenumber") {
			await this.setState({
				phoneNumber: value
			});
		}

		if (name === "firstname") {
			const isValid = value.length >= 1;

			await this.setState({
				firstName: value,
				firstNameIsValid: isValid,
				firstNameErrorMessage: !isValid ? "First Name is required" : ""
			});
		}

		if (name === "middlename") {
			await this.setState({
				middleName: value
			});
		}

		if (name === "lastname") {
			const isValid = value.length >= 1;

			await this.setState({
				lastName: value,
				lastNameIsValid: isValid,
				lastNameErrorMessage: !isValid ? "Last Name is required" : ""
			});
		}

		if (name === "password") {
			const isValid = this.state.confirmPassword == value;

			await this.setState({
				password: value,
				passwordIsValid: isValid,
				passwordErrorMessage: !isValid ? "Password and Confirm Password should match" : "",
				confirmPasswordIsValid: isValid,
				confirmPasswordErrorMessage: !isValid ? "Password and Confirm Password should match" : ""
			});
		}

		if (name === "confirmpassword") {
			const isValid = this.state.password == value;

			await this.setState({
				confirmPassword: value,
				passwordIsValid: isValid,
				passwordErrorMessage: !isValid ? "Password and Confirm Password should match" : "",
				confirmPasswordIsValid: isValid,
				confirmPasswordErrorMessage: !isValid ? "Password and Confirm Password should match" : ""
			});
		}

		if(name === "isactive" && this.hasAccess){
			await this.setState({
				isActive: !this.state.isActive
			});
		}

		if(name === "isemailverified" && this.hasAccess){
			await this.setState({
				isEmailVerified: !this.state.isEmailVerified
			});
		}

		if(name === "isphonenumberverified" && this.hasAccess){
			await this.setState({
				isPhoneNumberVerified: !this.state.isPhoneNumberVerified
			});
		}
	}

	handleSelectChange = (event: SelectChangeEvent<typeof this.state.roles>) => {
		const {
			target: { value },
		  } = event;
  
		  if(this.hasAccess){
			this.setState({
				roles: typeof value === 'string' ? value.split(',') : value
			});
		}
	  };

	handleCancel(event: React.MouseEvent<unknown>) {
		this.props.navigate("/admin/users");
	}

	handleSaveChanges(event: React.MouseEvent<unknown>) {
		this.saveChanges();
	}

	saveChanges(){
		const params = {
			userName: this.state.username,
			password: this.state.password,
			confirmPassword: this.state.confirmPassword,
			email: this.state.email,
			phoneNumber: this.state.phoneNumber,
			firstName: this.state.firstName,
			middleName: this.state.middleName,
			lastName: this.state.lastName,
			isActive: this.state.isActive,
			isPhoneNumberVerified: this.state.isPhoneNumberVerified,
			isEmailVerified: this.state.isEmailVerified,
			roles: this.state.roles
		  };

		if(this.props.showGlobalLoader)
			this.props.showGlobalLoader();

		if(this.state.id){
			http.put(`/users/${this.state.id}`, params)
			.then((response) => {
				if(this.hasAccess){
					this.props.navigate("/admin/users");
				}
				else{
					Toaster.Show("success", "Success", "User details updated successfuly!");
				}
			}).catch((error) => {
				let errors = error.data?.errors as Array<ValidationError>;
				if(errors){
					errors.map(item => {
						switch(item.property.toLocaleLowerCase()){
							case "username": {
								this.setState({
									usernameIsValid: false,
									usernameErrorMessage: item.message
								});
								break;
							}
							case "email": {
								this.setState({
									emailIsValid: false,
									emailErrorMessage: item.message
								});
								break;
							}
							case "phonenumber": {
								this.setState({
									phoneNumberIsValid: false,
									phoneNumberErrorMessage: item.message
								});
								break;
							}
							case "firstname": {
								this.setState({
									firstNameIsValid: false,
									firstNameErrorMessage: item.message
								});
								break;
							}
							case "middlename": {
								this.setState({
									middleNameIsValid: false,
									middleNameErrorMessage: item.message
								});
								break;
							}
							case "lastname": {
								this.setState({
									lastNameIsValid: false,
									lastNameErrorMessage: item.message
								});
								break;
							}
							case "password": {
								this.setState({
									passwordIsValid: false,
									passwordErrorMessage: item.message
								});
								break;
							}
							case "confirmpassword": {
								this.setState({
									confirmPasswordIsValid: false,
									confirmPasswordErrorMessage: item.message
								});
								break;
							}
							default: {
								Toaster.Show("error", "Error", item?.message);
								break;
							}
						}
					})
				}
				else{
					Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
				}
			})
			.finally(() => {
				if(this.props.hideGlobalLoader)
					this.props.hideGlobalLoader();
			});
		}
		else if(!this.state.id && this.hasAccess){
			http.post(`/users`, params)
				.then((response) => {
					if(this.hasAccess){
						this.props.navigate("/admin/users");
					}
					else{
						Toaster.Show("success", "Success", "User details updated successfuly!");
					}
				}).catch((error) => {
					let errors = error.data?.errors as Array<ValidationError>;
					if(errors){
						errors.map(item => {
							switch(item.property.toLocaleLowerCase()){
								case "username": {
									this.setState({
										usernameIsValid: false,
										usernameErrorMessage: item.message
									});
									break;
								}
								case "email": {
									this.setState({
										emailIsValid: false,
										emailErrorMessage: item.message
									});
									break;
								}
								case "phonenumber": {
									this.setState({
										phoneNumberIsValid: false,
										phoneNumberErrorMessage: item.message
									});
									break;
								}
								case "firstname": {
									this.setState({
										firstNameIsValid: false,
										firstNameErrorMessage: item.message
									});
									break;
								}
								case "middlename": {
									this.setState({
										middleNameIsValid: false,
										middleNameErrorMessage: item.message
									});
									break;
								}
								case "lastname": {
									this.setState({
										lastNameIsValid: false,
										lastNameErrorMessage: item.message
									});
									break;
								}
								case "password": {
									this.setState({
										passwordIsValid: false,
										passwordErrorMessage: item.message
									});
									break;
								}
								case "confirmpassword": {
									this.setState({
										confirmPasswordIsValid: false,
										confirmPasswordErrorMessage: item.message
									});
									break;
								}
								default: {
									Toaster.Show("error", "Error", item?.message);
									break;
								}
							}
						})
					}
					else{
						Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
					}
				})
				.finally(() => {
					if(this.props.hideGlobalLoader)
						this.props.hideGlobalLoader();
				});
		}
	}

	getRoles() {
		http.get(`/roles`)
		.then((response) => {
			let responseData = response.data as Array<RoleModel>;
			this.setState({
				allRoles: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	}

	getData(){
		if(this.props.showGlobalLoader)
			this.props.showGlobalLoader();

		http.get(`/users/${this.props.params?.id}`)
			.then((response) => {
				let responseData = response.data as UserModel;

				this.setState({
					id: responseData.id,
					username: responseData.userName,
					email: responseData.email,
					phoneNumber: responseData.phoneNumber ?? "",
					firstName: responseData.firstName,
					middleName: responseData.middleName ?? "",
					lastName: responseData.lastName,
					isActive: responseData.isActive,
					isEmailVerified: responseData.isEmailVerified,
					isPhoneNumberVerified: responseData.isPhoneNumberVerified,
					roles: responseData.roles?.map((item) => item.id)
				});
			}).catch((error) => {
				if(error?.response?.status === 403) {
					this.setState({
						insufficientAccess: true
					});
				}
				else {
					Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
				}
			})
			.finally(() => {
				if(this.props.hideGlobalLoader)
					this.props.hideGlobalLoader();
			});
	}

	render(): React.ReactNode {
		if(this.props.isUserLoggedIn ?? false) {
			if(this.state.insufficientAccess){
				return(<InsufficientAccess />);
			}

			return(
				<Paper>
					<form>
						<FormGroup 
							sx={{
								pt: { xs: 3 },
								//pb: { xs: 3 },
								pr: { xs: 2 },
								pl: { xs: 2 }
						}}>
							<Grid container>
								<Grid item xs={4}>
									<FormControl
										sx={{
											width: "100%",
											pr: { xs: 2}
										}}>
										<TextField
											required
											fullWidth
											disabled={this.props.params?.id != undefined || !this.hasAccess}
											name="username"
											value={this.state.username}
											inputProps={{
												form: {
												autoComplete: 'off',
												},
											}}
											label={this.props.intl.formatMessage({ id: "labels_username" })}
											error={this.state.usernameIsValid === false}
											onChange={this.onFieldChangeAsync.bind(this)}
											onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
											helperText={this.state.usernameErrorMessage} />
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl
										sx={{
											width: "100%",
											pr: { xs: 2}
										}}>
										<TextField
											required
											fullWidth
											name="email"
											value={this.state.email}
											label={this.props.intl.formatMessage({ id: "labels_email" })}
											error={this.state.emailIsValid === false}
											onChange={this.onFieldChangeAsync.bind(this)}
											onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
											helperText={this.state.emailErrorMessage} />
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl
										sx={{
											width: "100%"
										}}>
										<TextField
											fullWidth
											name="phonenumber"
											value={this.state.phoneNumber}
											label={this.props.intl.formatMessage({ id: "labels_phone_number" })}
											error={this.state.phoneNumberIsValid === false}
											onChange={this.onFieldChangeAsync.bind(this)}
											onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
											helperText={this.state.phoneNumberErrorMessage} />
									</FormControl>
								</Grid>
							</Grid>
						</FormGroup>
						<FormGroup 
							sx={{
								pt: { xs: 2 },
								//pb: { xs: 3 },
								pr: { xs: 2 },
								pl: { xs: 2 }
							}}>
							<Grid container>
								<Grid item xs={4}>
									<FormControl
										sx={{
											width: "100%",
											pr: { xs: 2}
										}}>
										<TextField
											required
											fullWidth
											name="firstname"
											value={this.state.firstName}
											label={this.props.intl.formatMessage({ id: "labels_first_name" })}
											error={this.state.firstNameIsValid === false}
											onChange={this.onFieldChangeAsync.bind(this)}
											onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
											helperText={this.state.firstNameErrorMessage} />
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl
										sx={{
											width: "100%",
											pr: { xs: 2}
										}}>
										<TextField
											fullWidth
											name="middlename"
											value={this.state.middleName}
											label={this.props.intl.formatMessage({ id: "labels_middle_name" })}
											error={this.state.middleNameIsValid === false}
											onChange={this.onFieldChangeAsync.bind(this)}
											onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
											helperText={this.state.middleNameErrorMessage} />
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl
										sx={{
											width: "100%",
											//pr: { xs: 2}
										}}>
										<TextField
											required
											fullWidth
											name="lastname"
											value={this.state.lastName}
											label={this.props.intl.formatMessage({ id: "labels_last_name" })}
											error={this.state.lastNameIsValid === false}
											onChange={this.onFieldChangeAsync.bind(this)}
											onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
											helperText={this.state.lastNameErrorMessage} />
									</FormControl>
								</Grid>
							</Grid>
						</FormGroup>
						<FormGroup 
							sx={{
								pt: { xs: 2 },
								//pb: { xs: 3 },
								pr: { xs: 2 },
								pl: { xs: 2 }
							}}>
							<Grid container>
								<Grid item xs={4}>
									<FormControl
										sx={{
											width: "100%",
											pr: { xs: 2}
										}}>
										<TextField
											fullWidth
											type="password"
											name="password"
											inputProps={{
												autoComplete: 'new-password',
												form: {
												autoComplete: 'off',
												},
											}}
											label={this.props.intl.formatMessage({ id: "labels_password" })}
											error={this.state.passwordIsValid === false}
											onChange={this.onFieldChangeAsync.bind(this)}
											onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
											helperText={this.state.passwordErrorMessage} />
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl
										sx={{
											width: "100%",
											pr: { xs: 2}
										}}>
										<TextField
											fullWidth
											type="password"
											name="confirmpassword"
											inputProps={{
												autoComplete: 'new-password',
												form: {
												autoComplete: 'off',
												},
											}}
											label={this.props.intl.formatMessage({ id: "labels_confirm_password" })}
											error={this.state.confirmPasswordIsValid === false}
											onChange={this.onFieldChangeAsync.bind(this)}
											onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
											helperText={this.state.confirmPasswordErrorMessage} />
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									{this.hasAccess && <FormControl
										disabled={this.props.currentUser?.id === this.props.params?.id}
										sx={{
											//pr: { xs: 3, sm: 3 },
											minWidth: "100%"
										}}>
										<InputLabel id="select-role-label">Role</InputLabel>
										<Select
											// sx={{
											// 	width: "100%"
											// }}
											labelId="select-role-label"
											input={<OutlinedInput id="select-role" label="Role" />}
											value={this.state.roles ?? ''}
											label="Role"
											onChange={this.handleSelectChange}>
											<MenuItem><em>None</em></MenuItem>
											{this.state?.allRoles?.map((item) => {
												return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
											})}
										</Select>
									</FormControl>}
								</Grid>
							</Grid>
						</FormGroup>
						<FormGroup 
							sx={{
								pt: { xs: 2 },
								//pb: { xs: 3 },
								pr: { xs: 2 },
								pl: { xs: 2 }
							}}>
							<Grid container>
								<Grid item xs={4}>
									<FormControl
										sx={{
											width: "100%",
											pr: { xs: 2}
										}}>
										<FormControlLabel 
											control={
												<Switch 
													disabled={!this.hasAccess}
													name="isactive" 
													onChange={this.onFieldChangeAsync.bind(this)} 
													checked={this.state.isActive } />} 
											label="Is Active?" />
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl
										sx={{
											width: "100%",
											pr: { xs: 2}
										}}>
										<FormControlLabel 
											control={
												<Switch 
													disabled={!this.hasAccess}
													name="isemailverified" 
													onChange={this.onFieldChangeAsync.bind(this)} 
													checked={this.state.isEmailVerified } />} 
											label="Is Email Verified?" />
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl
										sx={{
											width: "100%"
										}}>
										<FormControlLabel 
											control={
												<Switch 
													disabled={!this.hasAccess}
													name="isphonenumberverified" 
													onChange={this.onFieldChangeAsync.bind(this)} 
													checked={this.state.isPhoneNumberVerified} />} 
											label="Is Phone Number Verified?" />
									</FormControl>
								</Grid>
							</Grid>
						</FormGroup>
						<FormGroup 
							sx={{
								pt: { xs: 2 },
								pb: { xs: 3 },
								//pr: { xs: 2 },
								//pl: { xs: 2 }
							}}>
							<Grid container>
								<Grid item lg={9} md={8} sm={7} xs={6} />
								<Grid item lg={3} md={4} sm={5} xs={6} >
									<Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
										{this.hasAccess && <Button
											sx={{
												px: 2,
												mr: 1,
												ml: this.hasAccess ? "auto" : ""
											}}
											onClick={this.handleCancel.bind(this)}
											variant="outlined">
											Cancel
										</Button>}
										<Button
											sx={{
												//px: 2,
												//mr: 1,
												ml: !this.hasAccess ? "auto" : ""
											}}
											onClick={this.handleSaveChanges.bind(this)}
											variant="contained">
											Save
										</Button>
									</Box>
								</Grid>
							</Grid>
						</FormGroup>
					</form>
				</Paper>
			);
		}
	}
}

const mapStateToProps = (state: any) => {
	return {
		isUserLoggedIn: state.auth.isUserLoggedIn,
		currentUser: state.account.currentUser
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		showGlobalLoader: () =>
			dispatch(appActions.ShowGlobalLoader()),
		hideGlobalLoader: () =>
			dispatch(appActions.HideGlobalLoader()),
	};
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(User)));