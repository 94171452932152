import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Button, Fade, FormGroup, Grid, Input, Menu, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { red } from '@mui/material/colors';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CheckboxStates } from '../../../models/CheckboxStates';

type Order = 'asc' | 'desc';

export interface BaseData {
  id: string;
  cells: Array<JSX.Element>;
}

export interface HeadCell {
  disablePadding: boolean;
  id:  string;//keyof BaseData;
  label: string;
  numeric: boolean;
  orderEnabled: boolean;
  order?: Order;
}

interface CustomTableProps {
	data: Array<BaseData>;
  cells: Array<HeadCell>;
  recordsPerPage: Array<number>;
  recordPerPage: number;
  page: number;
  records: number;
  filters?: Array<JSX.Element>;
  selectedRows?: Array<string>;
  excludedRows?: Array<string>;
  headerCheckboxState?: CheckboxStates;
  handleRequestSort: {(event: React.MouseEvent<unknown>, property: string) : void};
  handleChangePage: {(event: unknown, newPage: number) : void};
  handleChangeRowsPerPage: {(event: React.ChangeEvent<HTMLInputElement>) : void };
  onFieldChangeAsync: {(event: React.ChangeEvent<HTMLInputElement>) : void};
  handleCheckAllRowsChange?: {(event: React.ChangeEvent<HTMLInputElement>) : void};
  handleCheckRowChange?: {(event: React.ChangeEvent<HTMLInputElement>) : void};
  handleSearch: {(event: unknown) : void};
  handleAdd?: {(event: unknown) : void};
  handleUpload?: {(event: unknown) : void};
  handleExport?: {(event: unknown) : void};
  handleDelete?: {(event: unknown) : void};
}

interface CustomTableState {

}

interface EnhancedTableToolbarProps {
  filters?: Array<JSX.Element>;
  handleSearch: {(event: unknown) : void};
  handleAdd?: {(event: unknown) : void};
  handleUpload?: {(event: unknown) : void};
  handleExport?: {(event: unknown) : void};
  handleDelete?: {(event: unknown) : void};
  onFieldChangeAsync: {(event: React.ChangeEvent<HTMLInputElement>) : void};
}

interface EnhancedTableProps {
  headCell: HeadCell[];
  headerCheckboxState?: CheckboxStates;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  handleCheckAllRowsChange?: {(event: React.ChangeEvent<HTMLInputElement>) : void};
}

function EnhancedTableToolbar(this: any, props: EnhancedTableToolbarProps) {
  const [advancedFilter, setAdvancedFilter] = React.useState(false);
  const advancedFilterChange = (event: unknown) => {
    setAdvancedFilter(!advancedFilter);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpload = (event: unknown) => {
    handleClose();
    if(props.handleUpload)
      props.handleUpload(event);
  }

  const handleExport = (event: unknown) => {
    handleClose();
    if(props.handleExport)
      props.handleExport(event);
  }

  const handleDelete = (event: unknown) => {
    handleClose();
    if(props.handleDelete)
      props.handleDelete(event);
  }

  return (
    <>
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        pt: { sm: 3 },
        pb: { sm: 3 }
      }}
    >
      <Paper
        component="form"
        elevation={3}
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          name="search"
          inputProps={{ 'aria-label': 'search text' }}
          onChange={props.onFieldChangeAsync.bind(this)}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={props.handleSearch}>
          <SearchIcon />
        </IconButton>
      </Paper>
      <Box sx={{ml: "auto"}}>
      {props.handleExport || props.handleUpload || props.handleDelete ?
      <>
        <Button
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="outlined"
          sx={{
            mr: {md: 2}
          }}
          onClick={handleClick}
        >
          Actions{open ? <ExpandLessIcon sx={{ml: 2}} /> : <ExpandMoreIcon sx={{ml: 2}} />}
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {props.handleUpload ?
          <MenuItem onClick={handleUpload}><FileUploadIcon sx={{mr: 1}}/>Upload</MenuItem> : 
          <></>}
          {props.handleExport ?
          <MenuItem onClick={handleExport}><FileDownloadIcon sx={{mr: 1}}/>Export</MenuItem> :
          <></>}
          {props.handleDelete ?
          <MenuItem sx={{color: red[500]}}  onClick={handleDelete}><DeleteIcon sx={{mr: 1}}/>Delete</MenuItem> :
          <></>}
        </Menu> 
      </>
      : <></>
      }
      {/* {props.handleUpload ?
        <Button
          sx={{
            mr: {md: 2}
          }}
          variant="outlined"
          onClick={props.handleUpload}>
          <FileUploadIcon sx={{mr: 1}}/>
          Upload
        </Button> :
        <></>} */}
        {props.handleAdd ?
        <Button
          sx={{
            mr: {md: 2}
          }}
          variant="contained"
          onClick={props.handleAdd}>
          <AddIcon sx={{mr: 1}}/>
          Add
        </Button> :
        <></>}
      {props.filters && props.filters.length > 0 ?
        <Tooltip title="Advanced filter" >
          <IconButton onClick={advancedFilterChange}>
            <FilterListIcon />
          </IconButton>
        </Tooltip> :
      <></>
      }
      </Box>
    </Toolbar>
    {advancedFilter && props.filters && props.filters.length > 0 ? 
    <Box>
      {props.filters}
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
          pb: { sm: 3 }
        }}>
        <Button
          sx={{
            ml: "auto"
          }}
          size='medium'
          variant='outlined'
          onClick={props.handleSearch}>
          <SearchIcon />
          Find
        </Button>
      </Toolbar>
    </Box> : <></>
    }
    </>
  );
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { headCell, onRequestSort } =
    props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {props.handleCheckAllRowsChange ? 
        <TableCell
            key="records-checkbox"
            align={'left'}
            padding={'normal'}
            sortDirection={false}
          >
          <Checkbox 
            indeterminate={props.headerCheckboxState == CheckboxStates.Partial} 
            checked={props.headerCheckboxState == CheckboxStates.All} 
            onChange={props.handleCheckAllRowsChange} />
        </TableCell> : <></>}
        {props.headCell.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={headCell.order ? headCell.order : false}
          >
            {headCell.orderEnabled ? 
            (<TableSortLabel
              active={headCell.order ? true : false}
              direction={headCell.order ? headCell.order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.order ? (
                <Box component="span" sx={visuallyHidden}>
                  {headCell.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>) : 
            <>{headCell.label}</>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

class CustomTable extends React.Component<CustomTableProps, CustomTableState> {
	constructor(props: CustomTableProps) {
		super(props);
	}

  render(): React.ReactNode {
		return (<>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar 
            filters={this.props.filters}
            onFieldChangeAsync={this.props.onFieldChangeAsync}
            handleSearch={this.props.handleSearch}
            handleAdd={this.props.handleAdd}
            handleUpload={this.props.handleUpload}
            handleDelete={this.props.handleDelete}
            handleExport={this.props.handleExport}/>
        </Paper>
      </Box>
			<Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'} >
              <EnhancedTableHead
                headCell={this.props.cells}
                headerCheckboxState={this.props.headerCheckboxState}
                handleCheckAllRowsChange={this.props.handleCheckAllRowsChange}
                onRequestSort={this.props.handleRequestSort}
              />
              <TableBody>
                {this.props.data.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={row.id}>
                      {this.props.handleCheckRowChange ?
                      <TableCell key={'checkbox-'+row.id} component="th" scope="row">
                        <Checkbox
                          checked={
                            this.props.headerCheckboxState == CheckboxStates.All || 
                            (this.props.selectedRows?.some((item) => item == row.id) ?? false) || 
                            !(this.props.excludedRows?.some((item) => item == row.id) ?? true)} 
                          id={row.id} 
                          onChange={this.props.handleCheckRowChange} />
                      </TableCell> :
                      <></>}
                      {row.cells.map(tableCell =>{
                        return (tableCell);
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={this.props.recordsPerPage}
            component="div"
            count={this.props.records}
            rowsPerPage={this.props.recordPerPage}
            page={!this.props.records || this.props.records <= 0 ? 0 : this.props.page}
            onPageChange={this.props.handleChangePage}
            onRowsPerPageChange={this.props.handleChangeRowsPerPage}
        />
        </Paper>
      </Box>
      </>
		);
	}
}

export default CustomTable;