import React, { ChangeEvent } from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import { connect } from "react-redux";
import { Box, Button, Container, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Switch, TextField } from "@mui/material";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import * as appActions from "../../../../store/actions/AppActions";
import { ValidationError } from "../../../../models/ValidationErrorModel";
import { TagModel } from "../../../../models/TagModels";
import { EmailSenderModel } from "../../../../models/EmailSenderModels";

interface EmailSenderProps extends WrappedComponentProps, WithRouterProps {
	showGlobalLoader?: Function,
	hideGlobalLoader?: Function,
}

interface EmailSenderState {
	id?: string;
	name: string;
	nameIsValid?: boolean;
	nameErrorMessage?: string;
    email: string;
    emailIsValid?: boolean;
    emailErrorMessage?: string;
    isActive: boolean;
}

class EmailSender extends React.Component<EmailSenderProps, EmailSenderState> {
	constructor(props: EmailSenderProps) {
		super(props);

		this.state = {
			name: "",
            email: "",
            isActive: true
		}
	}

	componentDidMount(): void {
		if(this.props.hideGlobalLoader)
			this.props.hideGlobalLoader();

		if(this.props.params?.id){
			this.getData();
		}
	}

	async onFieldChangeAsync(event: ChangeEvent<HTMLInputElement>) {
		const { name, value } = event.target;

		if (name === "name") {
			const isValid = value.length >= 1;

			await this.setState({
				name: value,
				nameIsValid: isValid,
				nameErrorMessage: !isValid ? "Name is required" : ""
			});
		}

        if (name === "email") {
			let emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

			const isValid = value.length >= 1 && emailRegex.test(value);

			await this.setState({
				email: value,
				emailIsValid: isValid,
				emailErrorMessage: !isValid ? "Email is required and should contain valid email address" : ""
			});
		}

        if(name === "isactive"){
			await this.setState({
				isActive: !this.state.isActive
			});
		}
	}

	handleCancel(event: React.MouseEvent<unknown>) {
		this.props.navigate("/admin/email-senders");
	}

	handleSaveChanges(event: React.MouseEvent<unknown>) {
		this.saveChanges();
	}

	saveChanges(){
		const params = {
			name: this.state.name,
            email: this.state.email,
            isActive: this.state.isActive
		};

		if(this.props.showGlobalLoader)
			this.props.showGlobalLoader();

		if(this.state.id){
			http.put(`/emailSenders/${this.state.id}`, params)
			.then((response) => {
				this.props.navigate("/admin/email-senders");
			}).catch((error) => {
				let errors = error.data?.errors as Array<ValidationError>;
				if(errors){
					errors.map(item => {
						switch(item.property.toLocaleLowerCase()){
							case "name": {
								this.setState({
									nameIsValid: false,
									nameErrorMessage: item.message
								});
								break;
							}
                            case "email": {
								this.setState({
									emailIsValid: false,
									emailErrorMessage: item.message
								});
								break;
							}
							default: {
								Toaster.Show("error", "Error", item?.message);
								break;
							}
						}
					})
				}
				else{
					Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
				}
			})
			.finally(() => {
				if(this.props.hideGlobalLoader)
					this.props.hideGlobalLoader();
			});
		}
		else{
			http.post(`/emailSenders`, params)
				.then((response) => {
					this.props.navigate("/admin/email-senders");
				}).catch((error) => {
					let errors = error.data?.errors as Array<ValidationError>;
					if(errors){
						errors.map(item => {
							switch(item.property.toLocaleLowerCase()){
								case "name": {
									this.setState({
										nameIsValid: false,
										nameErrorMessage: item.message
									});
									break;
								}
                                case "email": {
                                    this.setState({
                                        emailIsValid: false,
                                        emailErrorMessage: item.message
                                    });
                                    break;
                                }
								default: {
									Toaster.Show("error", "Error", item?.message);
									break;
								}
							}
						})
					}
					else{
						Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
					}
				})
				.finally(() => {
					if(this.props.hideGlobalLoader)
						this.props.hideGlobalLoader();
				});
		}
	}

	getData(){
		if(this.props.showGlobalLoader)
			this.props.showGlobalLoader();

		http.get(`/emailSenders/${this.props.params?.id}`)
			.then((response) => {
				let responseData = response.data as EmailSenderModel;

				this.setState({
					id: responseData.id,
					name: responseData.name,
                    email: responseData.email,
                    isActive: responseData.isActive
				});
			}).catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			})
			.finally(() => {
				if(this.props.hideGlobalLoader)
					this.props.hideGlobalLoader();
			});
	}

	render(): React.ReactNode {
		return(
			<Paper>
				<form>
					<FormGroup 
						sx={{
							pt: { xs: 3 },
							//pb: { xs: 3 },
							pr: { xs: 2 },
							pl: { xs: 2 }
					}}>
						<Grid container>
							<Grid item xs={5}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<TextField
										required
										fullWidth
										name="name"
										value={this.state.name}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_name" })}
										error={this.state.nameIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.nameErrorMessage} />
								</FormControl>
							</Grid>
                            <Grid item xs={5}>
                                <FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<TextField
										required
										fullWidth
										name="email"
										value={this.state.email}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_email" })}
										error={this.state.emailIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.emailErrorMessage} />
								</FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl
                                    sx={{
                                        width: "100%",
                                        pr: { xs: 2}
                                    }}>
									<FormControlLabel 
										control={
											<Switch 
												name="isactive" 
												onChange={this.onFieldChangeAsync.bind(this)} 
												checked={this.state.isActive } />} 
										label="Is Active?" />
								</FormControl>
                            </Grid>
						</Grid>
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							pb: { xs: 3 },
							//pr: { xs: 2 },
							//pl: { xs: 2 }
						}}>
						<Grid container>
							<Grid item lg={9} md={8} sm={7} xs={6} />
							<Grid item lg={3} md={4} sm={5} xs={6} >
								<Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
									<Button
										sx={{
											px: 2,
											mr: 1,
											ml: "auto"
										}}
										onClick={this.handleCancel.bind(this)}
										variant="outlined">
										Cancel
									</Button>
									<Button
										sx={{
											px: 2,
										}}
										onClick={this.handleSaveChanges.bind(this)}
										variant="contained">
										Save
									</Button>
								</Box>
							</Grid>
						</Grid>
					</FormGroup>
				</form>
			</Paper>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		showGlobalLoader: () =>
			dispatch(appActions.ShowGlobalLoader()),
		hideGlobalLoader: () =>
			dispatch(appActions.HideGlobalLoader()),
	};
};

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(EmailSender)));