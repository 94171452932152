import React from "react";
import { Outlet } from "react-router-dom";
import { AppBar, Box, Divider, Drawer, Toolbar, Typography } from "@mui/material";
import AppTheme from "../../../styles/AppTheme";
import AdminPortalTopNavigation from "./AdminPortalTopNavigation";
import AdminPortalSideNavigation from "./AdminPortalSideNavigation";
import * as appActions from "../../../store/actions/AppActions";
import { appConfig } from "../../../AppConfig";
import { connect } from "react-redux";
import { ApplicationUser } from "../../../models/AuthModels";
import { WithRouterProps, withRouter } from "../../../router";
import { WrappedComponentProps, injectIntl } from "react-intl";

const hasWindow = typeof window !== 'undefined';

interface AdminPortalMasterProps extends WithRouterProps, WrappedComponentProps {
	isUserLoggedIn?: boolean,
	currentUser?: ApplicationUser,
	hideGlobalLoader?: Function,
}

interface AdminPortalMasterState {
	drawerWidth: number,
	currentPage: string,
	currentLocation: string
}

class AdminPortalMaster extends React.Component<AdminPortalMasterProps, AdminPortalMasterState> {
	appHeader: React.RefObject<any>;
	hasAccess: boolean;

	constructor(props: AdminPortalMasterProps) {
		super(props);
		let roles = this.props.currentUser?.roles ?? [];

		this.appHeader = React.createRef();
		// this.hasAccess = (this.props.isUserLoggedIn
		// 	&& roles.includes(appConfig.roles.ADMIN)) ?? false;
		this.hasAccess = this.props.isUserLoggedIn ?? false;
		const width = hasWindow ? window.innerWidth : null;

		this.state = {
			drawerWidth: (width && width < 900 ? 60 : 220),
			currentPage: "",
			currentLocation: ""
		};
	}

	componentDidMount() {
		if(this.props.hideGlobalLoader)
			this.props.hideGlobalLoader();

		if (!this.hasAccess) {
			return setTimeout(() => this.props.navigate("/admin/login", { replace: true }));
		}

		window.addEventListener("resize", this.handleResize);
		this.assessLocation(this.props.location?.pathname);
	}

	componentDidUpdate(prevProps: any) {
		if (this.props.location?.pathname !== prevProps.location?.pathname) {
			this.assessLocation(this.props.location?.pathname);
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize = () => {
		const width = hasWindow ? window.innerWidth : null;
		if(width && width < 900){
			this.setState({
				drawerWidth: 60
			});
		}
		else{
			this.setState({
				drawerWidth: 220
			});
		}
	};

	assessLocation = (location: string) => {
		let locationString: any;
		let guidRegex = /\w{8}\-\w{4}\-\w{4}\-\w{4}\-\w{12}/;
		let regexMatch = location.match(guidRegex);
		var isEdit = false;

		if(regexMatch && regexMatch[0]){
			location = location.replace("/"+regexMatch[0], "");
			isEdit = true;
		}

		switch (location) {
			case "/admin":
				locationString = this.props.intl.formatMessage({ id: "labels_dashboard" });
				break;
			case "/admin/users":
				locationString = !isEdit ? this.props.intl.formatMessage({ id: "labels_users" }) : this.props.intl.formatMessage({ id: "labels_users_edit" });
				break;
			case "/admin/users/create":
				locationString = this.props.intl.formatMessage({ id: "labels_users_create" });
				break;
			case "/admin/contacts":
				locationString = !isEdit ? this.props.intl.formatMessage({ id: "labels_contacts" }) : this.props.intl.formatMessage({ id: "labels_contacts_edit" });
				break;
			case "/admin/contacts/create":
				locationString = this.props.intl.formatMessage({ id: "labels_contacts_create" });
				break;
			case "/admin/campaigns":
				locationString = !isEdit ? this.props.intl.formatMessage({ id: "labels_campaigns" }) : this.props.intl.formatMessage({ id: "labels_campaigns_edit" });
				break;
			case "/admin/campaigns/create":
				locationString = this.props.intl.formatMessage({ id: "labels_campaigns_create" });
				break;
			case "/admin/flows":
				locationString = !isEdit ? this.props.intl.formatMessage({ id: "labels_flows" }) : this.props.intl.formatMessage({ id: "labels_flows_edit" });
				break;
			case "/admin/flows/create":
				locationString = this.props.intl.formatMessage({ id: "labels_flows_create" });
				break;
			case "/admin/tags":
				locationString = !isEdit ? this.props.intl.formatMessage({ id: "labels_tags" }) : this.props.intl.formatMessage({ id: "labels_tags_edit" });
				break;
			case "/admin/tags/create":
				locationString = this.props.intl.formatMessage({ id: "labels_tags_create" });
				break;
			case "/admin/email-templates":
				locationString = !isEdit ? this.props.intl.formatMessage({ id: "labels_email_templates" }) : this.props.intl.formatMessage({ id: "labels_email_template_edit" });
				break;
			case "/admin/email-templates/create":
				locationString = this.props.intl.formatMessage({ id: "labels_email_template_create" });
				break;
			case "/admin/email-senders":
				locationString = !isEdit ? this.props.intl.formatMessage({ id: "labels_email_senders" }) : this.props.intl.formatMessage({ id: "labels_email_sender_edit" });
				break;
			case "/admin/email-senders/create":
				locationString = this.props.intl.formatMessage({ id: "labels_email_sender_create" });
				break;
			case "/admin/settings":
				locationString = this.props.intl.formatMessage({ id: "labels_settings" });
				break;
			default:
		}

		this.setState({
			currentPage: locationString,
			currentLocation: location
		});
	};

	render() {
		if (this.hasAccess)
			return (
				<>
					<AppTheme>
						<Box sx={{ display: 'flex' }}>
							<AppBar
								ref={this.appHeader}
								position="fixed"
								elevation={0}
								sx={{
									color: AppTheme.CurrentTheme.palette.text.primary,
									background: 'white',
									width: { xs: `calc(100% - ${this.state.drawerWidth}px)` },
									ml: { xs: `${this.state.drawerWidth}px` },
									borderBottom: `1px solid ${AppTheme.CurrentTheme.palette.primary.contrastText}`
								}}>
								<Toolbar>
									<AdminPortalTopNavigation routeName={this.state.currentPage} />
								</Toolbar>
							</AppBar>

							{/* Main Side Nav */}
							<Drawer 
								sx={{
									display: { xs: 'block', sm: 'block', md: 'block' },
									'& .MuiDrawer-paper': { boxSizing: 'border-box', width: `${this.state.drawerWidth}px` },
								}}
								variant="persistent"
								anchor="left"
								open={true}>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										height: `${this.appHeader.current?.offsetHeight}px`
									}}>
									{/* Add the main logo */}
									<Typography
										margin="auto"
										//marginLeft={"2rem"}
										variant="h5">
										CMS
									</Typography>
								</Box>
								<Divider sx={{ width: "100%", marginBottom: "1.5rem" }} />
								<AdminPortalSideNavigation
									currentLocation={this.state.currentLocation}
									drawerWidth={this.state.drawerWidth}
								/>
							</Drawer>

							{/* Main content */}
							<Box
								component="main"
								sx={{
									flexGrow: 1,
									p: 3,
									overflow: "hidden",
									width: { xs: `calc(100% - ${this.state.drawerWidth}px)` },
									mt: { xs: `${this.appHeader.current?.offsetHeight}px` },
									ml: { xs: `${this.state.drawerWidth}px` }
								}}>
								<Box
									sx={{
										minWidth: '700px',
									}}>
									<Outlet />
								</Box>
							</Box>
						</Box>
					</AppTheme>
				</>
			);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isUserLoggedIn: state.auth.isUserLoggedIn,
		currentUser: state.account.currentUser
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		hideGlobalLoader: () =>
			dispatch(appActions.HideGlobalLoader())
	};
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdminPortalMaster)));