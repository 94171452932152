import React, { ChangeEvent } from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import { connect } from "react-redux";
import { Box, Button, Container, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Switch, TextField } from "@mui/material";
import http from "../../../../services/HttpService";
import * as appActions from "../../../../store/actions/AppActions";
import Toaster from "../../../controls/toaster/Toaster";
import { ValidationError } from "../../../../models/ValidationErrorModel";
import { TagModel } from "../../../../models/TagModels";

interface TagProps extends WrappedComponentProps, WithRouterProps {
	showGlobalLoader?: Function,
	hideGlobalLoader?: Function,
}

interface TagState {
	id?: string;
	name: string;
	nameIsValid?: boolean;
	nameErrorMessage?: string;
    isSystem: boolean;
}

class Tag extends React.Component<TagProps, TagState> {
	constructor(props: TagProps) {
		super(props);

		this.state = {
			name: "",
            isSystem: false
		}
	}

	componentDidMount(): void {
		if(this.props.hideGlobalLoader)
			this.props.hideGlobalLoader();

		this.loadData();
	}

	async loadData(){
		if(this.props.params?.id){
			this.getData();
		}
	}

	async onFieldChangeAsync(event: ChangeEvent<HTMLInputElement>) {
		const { name, value } = event.target;

		if (name === "name") {
			const isValid = value.length >= 1;

			await this.setState({
				name: value,
				nameIsValid: isValid,
				nameErrorMessage: !isValid ? "Name is required" : ""
			});
		}
	}

	handleCancel(event: React.MouseEvent<unknown>) {
		this.props.navigate("/admin/tags");
	}

	handleSaveChanges(event: React.MouseEvent<unknown>) {
		this.saveChanges();
	}

	saveChanges(){
		const params = {
			name: this.state.name
		};

		if(this.props.showGlobalLoader)
			this.props.showGlobalLoader();

		if(this.state.id){
			http.put(`/tags/${this.state.id}`, params)
			.then((response) => {
				this.props.navigate("/admin/tags");
			}).catch((error) => {
				let errors = error.data?.errors as Array<ValidationError>;
				if(errors){
					errors.map(item => {
						switch(item.property.toLocaleLowerCase()){
							case "name": {
								this.setState({
									nameIsValid: false,
									nameErrorMessage: item.message
								});
								break;
							}
							default: {
								Toaster.Show("error", "Error", item?.message);
								break;
							}
						}
					})
				}
				else{
					Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
				}
			})
			.finally(() => {
				if(this.props.hideGlobalLoader)
					this.props.hideGlobalLoader();
			});
		}
		else{
			http.post(`/tags`, params)
				.then((response) => {
					this.props.navigate("/admin/tags");
				}).catch((error) => {
					let errors = error.data?.errors as Array<ValidationError>;
					if(errors){
						errors.map(item => {
							switch(item.property.toLocaleLowerCase()){
								case "name": {
									this.setState({
										nameIsValid: false,
										nameErrorMessage: item.message
									});
									break;
								}
								default: {
									Toaster.Show("error", "Error", item?.message);
									break;
								}
							}
						})
					}
					else{
						Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
					}
				})
				.finally(() => {
					if(this.props.hideGlobalLoader)
						this.props.hideGlobalLoader();
				});
		}
	}

	getData(){
		if(this.props.showGlobalLoader)
			this.props.showGlobalLoader();

		http.get(`/tags/${this.props.params?.id}`)
			.then((response) => {
				let responseData = response.data as TagModel;

				this.setState({
					id: responseData.id,
					name: responseData.name,
                    isSystem: responseData.isSystem
				});
			}).catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			})
			.finally(() => {
				if(this.props.hideGlobalLoader)
					this.props.hideGlobalLoader();
			});
	}

	render(): React.ReactNode {
		return(
			<Paper>
				<form>
					<FormGroup 
						sx={{
							pt: { xs: 3 },
							//pb: { xs: 3 },
							pr: { xs: 2 },
							pl: { xs: 2 }
					}}>
						<Grid container>
							<Grid item xs={6} sm={6} md={4}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<TextField
										required
										fullWidth
										disabled={this.state.isSystem}//{this.props.params?.id != undefined}
										name="name"
										value={this.state.name}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_name" })}
										error={this.state.nameIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.nameErrorMessage} />
								</FormControl>
							</Grid>
							<Grid item xs={6} sm={6} md={4} />
						</Grid>
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							pb: { xs: 3 },
							//pr: { xs: 2 },
							//pl: { xs: 2 }
						}}>
						<Grid container>
							<Grid item lg={9} md={8} sm={7} xs={6} />
							<Grid item lg={3} md={4} sm={5} xs={6} >
								<Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
									<Button
										sx={{
											px: 2,
											mr: 1,
											ml: "auto"
										}}
										onClick={this.handleCancel.bind(this)}
										variant="outlined">
										Cancel
									</Button>
									<Button
										sx={{
											px: 2,
										}}
                                        disabled={this.state.isSystem}
										onClick={this.handleSaveChanges.bind(this)}
										variant="contained">
										Save
									</Button>
								</Box>
							</Grid>
						</Grid>
					</FormGroup>
				</form>
			</Paper>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		showGlobalLoader: () =>
			dispatch(appActions.ShowGlobalLoader()),
		hideGlobalLoader: () =>
			dispatch(appActions.HideGlobalLoader()),
	};
};

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(Tag)));