export enum FlowRuleTrigger {
    None = 0,
    TimeTrigger = 1
}

export interface FlowStepModel{
    id: string,
    name: string,
    trigger: FlowRuleTrigger,
    interval: string,
    emailTemplateId: string,
    emailTemplate: string,
    emailSenderId: string,
    emailSender: string,
    order: number,
    isActive: boolean
};