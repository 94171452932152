import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';
import { PersistGate } from 'redux-persist/integration/react';
import appReducer from './store/reducers/AppReducer';
import authReducer from './store/reducers/AuthReducer';
import accountReducer from './store/reducers/AccountReducer';

import AppTheme from './styles/AppTheme';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

const persistConfig = {
	key: 'root',
	storage
}

const rootReducers = {
	auth: authReducer,
	account: accountReducer,
	app: appReducer
};

const persistedReducer = persistCombineReducers(persistConfig, rootReducers);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false
	})
	.concat(thunk)
});

const persistor = persistStore(store)

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	// <React.StrictMode>
		<Provider store={store}>
			{/* <AppTheme> */}
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			{/* </AppTheme> */}
		</Provider>
	// </React.StrictMode>
);
// root.render(
// 	<Provider store={store}>
// 			{/* <AppTheme> */}
// 				<PersistGate loading={null} persistor={persistor}>
// 					<App />
// 				</PersistGate>
// 			{/* </AppTheme> */}
// 		</Provider>
// );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
