export enum Status {
    None = 0,
    Pending = 1,
    Processing = 2,
    Finished = 3
}

export interface CampaignListModel {
    id: string;
    name: string;
    description: string;
    emailTemplateId: string;
    emailTemplate: string;
    countryId: string;
    country: string;
    status: Status;
    startAt: string;
    endAt: string;
    emailOpenFrom?: number;
    emailOpenTo?: number;
    tag?: string;
    emailSender?: string;
}

export interface CampaignModel {
    id: string;
    name: string;
    desription: string;
    emailTemplateId: string;
    emailTemplate: string;
    countryId: string;
    status: Status;
    startAt: string;
    emailOpenFrom?: number;
    emailOpenTo?: number;
    tagId?: string;
    tag?: string;
    emailSenderId?: string;
    emailSender?: string;
}