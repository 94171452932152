import React, { ChangeEvent } from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import { connect } from "react-redux";
import { Box, Button, Container, FormControl, FormControlLabel, FormGroup, Grid, Paper, TextField } from "@mui/material";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import * as appActions from "../../../../store/actions/AppActions";
import { ValidationError } from "../../../../models/ValidationErrorModel";
import { LimitSettingModel } from "../../../../models/LimitSettingModels";

interface SettingProps extends WrappedComponentProps, WithRouterProps {
	showGlobalLoader?: Function,
	hideGlobalLoader?: Function,
}

interface SettingState {
	batchSize: number,
    batchSizeIsValid?: boolean,
    batchSizeErrorMessage?: string,
    thresholdInterval: string,
    thresholdIntervalIsValid?: boolean,
    thresholdIntervalErrorMessage?: string,
}

class Setting extends React.Component<SettingProps, SettingState> {
	constructor(props: SettingProps) {
		super(props);

		this.state = {
            batchSize: 0,
			thresholdInterval: ""
		};
	}

	componentDidMount(): void {
		if(this.props.hideGlobalLoader)
			this.props.hideGlobalLoader();

		this.getData();
	}

	async onFieldChangeAsync(event: ChangeEvent<HTMLInputElement>) {
		const { name, value } = event.target;

		if (name === "batchsize") {
            const isNegativeCharacter = value.startsWith("-") && value.length === 1;

			const numberValue = parseInt(isNegativeCharacter ? value+"0" : (value === "" ? "0" : value) , 10);

			const isValid = numberValue >= 0;

			await this.setState({
				batchSize: numberValue,
				batchSizeIsValid: isValid,
				batchSizeErrorMessage: !isValid ? "Batch size should be >= 0" : ""
			});
		}

        if(name === "thresholdinterval") {
            const regex = /^(\d+):([0-1]?\d|2[0-3]):([0-5]?\d)$/;
            const isValid = regex.test(value);

            await this.setState({
                thresholdInterval: value,
                thresholdIntervalIsValid: isValid,
                thresholdIntervalErrorMessage: !isValid ? "Threshold interval should match d:hh:mm format" : ""
            })
        }
	}

	handleSaveChanges(event: React.MouseEvent<unknown>) {
		this.saveChanges();
	}

    handleCancel(event: React.MouseEvent<unknown>) {
		this.getData();
        this.setState({
            thresholdIntervalIsValid: true,
            thresholdIntervalErrorMessage: "",
            batchSizeIsValid: true,
            batchSizeErrorMessage: ""
        });
	}

	saveChanges(){
		const params = {
			batchSize: this.state.batchSize,
			thresholdInterval: this.state.thresholdInterval
		};

		if(this.props.showGlobalLoader)
			this.props.showGlobalLoader();

        http.put(`/limitSettings`, params)
        .then((response) => {
            Toaster.Show("success", "Success", "Settings are successfully saved!");
        }).catch((error) => {
            let errors = error.data?.errors as Array<ValidationError>;
            if(errors){
                errors.map(item => {
                    switch(item.property.toLocaleLowerCase()){
                        case "batchsize": {
                            this.setState({
                                batchSizeIsValid: false,
                                batchSizeErrorMessage: item.message
                            });
                            break;
                        }
                        case "thresholdinterval": {
                            this.setState({
                                thresholdIntervalIsValid: false,
                                thresholdIntervalErrorMessage: item.message
                            });
                            break;
                        }
                        default:
                            break;
                    }
                })
            }
            else{
                Toaster.Show("error", "Error", error.data?.message);
            }
        })
		.finally(() => {
			if(this.props.hideGlobalLoader)
				this.props.hideGlobalLoader();
		});
	}

	getData(){
		if(this.props.showGlobalLoader)
			this.props.showGlobalLoader();

		http.get(`/limitSettings/`)
			.then((response) => {
				let responseData = response.data as LimitSettingModel;

				this.setState({
					thresholdInterval: responseData.thresholdInterval,
                    batchSize: responseData.batchSize
				});
			}).catch((error) => {
				Toaster.Show("error", "Error", error.data.message);
			})
			.finally(() => {
				if(this.props.hideGlobalLoader)
					this.props.hideGlobalLoader();
			});
	}

	render(): React.ReactNode {
		return(
			<Paper>
				<form>
					<FormGroup 
						sx={{
							pt: { xs: 3 },
							//pb: { xs: 3 },
							pr: { xs: 2 },
							pl: { xs: 2 }
					}}>
						<Grid container>
							<Grid item xs={6} sm={6} md={4}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<TextField
										required
										fullWidth
										name="batchsize"
										value={this.state.batchSize.toString()}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_batch_size" })}
										error={this.state.batchSizeIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.batchSizeErrorMessage} />
								</FormControl>
							</Grid>
							<Grid item xs={6} sm={6} md={4}>
                            	<FormControl
									sx={{
										width: "100%",
										pr: { xs: 0, md: 2}
									}}>
									<TextField
										required
										fullWidth
										name="thresholdinterval"
										value={this.state.thresholdInterval}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
                                        }}
                                        placeholder="d:hh:mm"
										label={this.props.intl.formatMessage({ id: "labels_threshold_interval" })}
										error={this.state.thresholdIntervalIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.thresholdIntervalErrorMessage} />
								</FormControl>
							</Grid>
                            <Grid item xs={0} sm={0} md={4} />
						</Grid>
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							pb: { xs: 3 },
							//pr: { xs: 2 },
							//pl: { xs: 2 }
						}}>
						<Grid container>
							<Grid item lg={9} md={8} sm={7} xs={6} />
							<Grid item lg={3} md={4} sm={5} xs={6} >
                                <Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
									<Button
										sx={{
											px: 2,
											mr: 1,
											ml: "auto"
										}}
										onClick={this.handleCancel.bind(this)}
										variant="outlined">
										Cancel
									</Button>
									<Button
										sx={{
											px: 2,
										}}
										onClick={this.handleSaveChanges.bind(this)}
										variant="contained">
										Save
									</Button>
								</Box>
							</Grid>
						</Grid>
					</FormGroup>
				</form>
			</Paper>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		showGlobalLoader: () =>
			dispatch(appActions.ShowGlobalLoader()),
		hideGlobalLoader: () =>
			dispatch(appActions.HideGlobalLoader()),
	};
};

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(Setting)));